<template>
    <div id="log_manage">
        <jy-query ref="form" :model="form">
            <jy-query-item prop="loginUser" label="登录账号:">
                <el-input placeholder="请输入" v-model="form.loginUser"></el-input>
            </jy-query-item>
            <jy-query-item prop="loginNa" label="用户名称:">
                <el-input placeholder="请输入" v-model="form.loginNa"></el-input>
            </jy-query-item>
            <jy-query-item prop="orgId" label="所属机构:">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.orgNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="查询时间" prop="createT">
                <el-date-picker type="daterange" value-format="yyyy-MM-dd" v-model="form.createT"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('wastesSystemSettingsLogonLogList')">查询</el-button>
                <el-button type="primary" @click="resetForm('form')" v-if="btnexist('wastesSystemSettingsLogonLogReset')">重置</el-button>
                <el-row>
                    <!-- <el-button type="primary">导出</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <jy-table :data="dataList" v-loading="loading">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column prop="loginUser" label="登录账号"></jy-table-column>
            <jy-table-column prop="loginNa" label="用户名称"></jy-table-column>
            <jy-table-column prop="orgNa" label="所属机构"></jy-table-column>
            <jy-table-column prop="roleNa" label="所属角色"></jy-table-column>
            <jy-table-column prop="loginIp" label="所属IP"></jy-table-column>
            <jy-table-column prop="loginT" label="系统登录时间"></jy-table-column>
            <!-- <jy-table-column prop="logoutT" label="系统退出时间"></jy-table-column> -->
        </jy-table>
        <jy-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageIndex"
            :page-size="pageSize"
            :total="total"
        ></jy-pagination>

        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" title="选择上级机构"></institutions-tree>
    </div>
</template>
<script>
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        data() {
            return {
                form: {
                    loginUser: "",
                    loginNa: "",
                    orgNa: "",
                    orgId: "",
                    createT: []
                },
                pageSize: 10,
                total: 100,
                pageIndex: 1,
                dataList: [],
                loading:false,

                btnMenuId: "wastesSystemSettingsLogonLog"
            };
        },
        mixins: [btnMixins],
        created() {
            this.getList();
        },
        components: {
            institutionsTree
        },
        methods: {
            // 选择机构树
            selectInstitutions() {
                this.$refs.institutions.init();
            },
            // 上级机构查询
            addInstitutions(data) {
                this.form.orgId = data.orgId;
                this.form.orgNa = data.orgNa;
            },
            getList() {
                let option = {
                    ...this.form,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    createBeginTStr: this.$util.addTime(this.form.createT && this.form.createT[0], true),
                    createEndTStr: this.$util.addTime(this.form.createT && this.form.createT[1]),
                    //createT: undefined
                };

                let url = "/system/userLog/queryUserLoginLog";
                this.loading = true
                this.$http.post(url, option).then(({ detail }) => {
                    this.loading = false
                    this.dataList = detail.list;
                    this.total = detail.total;
                },()=>{
                    this.loading = false
                });
            },
            // 查询表格
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.form.orgNa = "";
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            }
        }
    };
</script>
